import { isRouteErrorResponse } from '@remix-run/react';

import { publicEnv } from '~/constants/env-client';


const { STAGE } = publicEnv();


/**
 * Error class for standard errors in the application.
 * These may be publicly displayed to the user.
 */
export class StandardError extends Error {
  static defaultErrorCode = 'Oops';
  static defaultErrorTitle = "Something doesn't look right";
  static defaultErrorMessage = 'Our team has been notified and will look into it shortly.';

  public title?: string;
  public code?: string | number;
  public override cause?: unknown;
  public override stack?: string;

  constructor(
    /** User-friendly error message, like `This workspace couldn't be found...`. */
    message: string,
    options: {
      /** Error title, like "Workspace not found." */
      title?: string;
      /** HTTP status code, like `404`, or custom code like `workspace_not_found`. */
      code?: string | number;
      cause?: unknown;
      stack?: string;
    } = {},
  ) {
    super(message, {
      cause: options?.cause,
    });
    this.title = options?.title;
    this.cause = options?.cause;
    this.code = options?.code;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else if (options?.stack) {
      this.stack = options?.stack;
    }
  }

  /**
   * Helper for making `StandardErrors` that serialize correctly when thrown inside a deferred promise.
   * This works by serializing the error into `message` then parsing it client-side.
   *
   * @example new StandardError('This is an error').deferrable()
   */
  deferrable = () => {
    this.message = this.safeSerialize();
    return this;
  };

  /**
   * Create a new error from an existing unknown error or object.
   */
  static from = (error: unknown) => {
    const toParse = (
      !!error
      && typeof error === 'object'
      && 'message' in error
      && typeof error.message === 'string'
      && error.message.startsWith('{"')
    )
        ? JSON.parse(error.message)
        : error;

    const { code, title, message, cause, stack } = getCommonErrorInfo(toParse);

    return new StandardError(message, {
      title,
      code,
      cause,
      stack,
    });
  };

  /**
   * Safely serialize the error for transport to the client.
   * This SHOULD NOT include any sensitive information, like stack traces.
   */
  safeSerialize = () => {
    return JSON.stringify({
      message: this.message,
      title: this.title,
      code: this.code,
      ...(STAGE !== 'prod' && {
        cause: this.cause,
        stack: this.stack,
      }),
    });
  };
}

/**
 * Given an unknown error, extract common error info like code, title, message, and cause.
 */
export const getCommonErrorInfo = (error: unknown) => {
  let code: string | number = StandardError.defaultErrorCode;
  let title = StandardError.defaultErrorTitle;
  let message = StandardError.defaultErrorMessage;
  let cause: unknown = undefined;
  let stack: string | undefined = undefined;

  if (isRouteErrorResponse(error)) {
    code = error.status || code;
    title = error.statusText || title;

    if (typeof error.data === 'string') {
      message = error.data;
    } else if (!!error.data && typeof error.data === 'object') {
      if ('message' in error.data && typeof error.data.message === 'string' && error.data.message.length) {
        message = error.data.message;
      }
    }
  } else {
    if (!!error && typeof error === 'object') {
      if ('code' in error && (typeof error.code === 'string' || typeof error.code === 'number')) {
        code = error.code;
      }
      if ('title' in error && typeof error.title === 'string' && error.title.length) {
        title = error.title;
      }
      if ('message' in error && typeof error.message === 'string' && error.message.length) {
        message = error.message;
      }
      if ('stack' in error && typeof error.stack === 'string' && error.stack.length) {
        stack = error.stack;
      }
      if ('cause' in error) {
        cause = error.cause;
      }
    }
  }

  return {
    code,
    title,
    message: message.replace('Invariant failed: ', ''),
    cause,
    stack,
  };
};
