import { type FC, isValidElement, type ReactNode } from 'react';
import { type ErrorResponse, isRouteErrorResponse, useNavigate, useParams, useRouteError } from '@remix-run/react';

import * as Sentry from '@sentry/remix';
import { $path } from 'remix-routes';
import { match, P } from 'ts-pattern';

import { StandardButton } from '~/components/buttons/StandardButton';
import { getCommonErrorInfo, StandardError } from '~/components/misc/StandardError';
import { publicEnv } from '~/constants/env-client';
import { cn } from '~/utils/general';


const { isProd } = publicEnv();

/**
 * Error props that can be returned from the status handler for rendering.
 */
type ErrorProps = {
  code?: string | number;
  title?: string;
  message?: string;
  // Additional options, like showSupportLink, etc.
  hideSupportLink?: boolean;
  hideBackButton?: boolean;
};

/**
 * Error info data passed down from the error boundary.
 */
type ErrorInfo<IsRoute extends boolean> = {
  error: IsRoute extends true ? ErrorResponse : ErrorResponse | unknown;
  params: Record<string, string | undefined>;
};

// ! TODO: Add support link

/**
 * Default status handler function used to render error messages.
 */
const DefaultStatusHandlerFunc: FC<ErrorInfo<false> & {
  errorProps?: ErrorProps;
}> = (input) => {
  const { error, errorProps, params } = input;

  const navigate = useNavigate();
  const isErrorInstance = error instanceof Error; // Error object

  const { code, title, message } = match(input)
    .returnType<ErrorProps>()
    .with({ errorProps: { message: P.string } }, ({ errorProps }) => errorProps)
    .otherwise(() => {
      const errorInfo = getCommonErrorInfo(error);
      return {
        code: errorInfo?.code,
        title: errorInfo?.title,
        message: errorInfo?.message,
      };
    });

  const hideBackButton = errorProps?.hideBackButton;
  const hideSupportLink = errorProps?.hideSupportLink;
  const stackTrace = isErrorInstance ? error?.stack : undefined;

  return (
    <main className="grid px-6 lg:px-8">
      <div className="text-center">
        {/* Status code, title, and error message */}
        <p className="text-base font-semibold text-blue-600">{code}</p>
        <h1 className="mt-4 text-3xl font-medium tracking-tight text-gray-900">{title}</h1>
        <p className="mt-4 text-base leading-7 text-gray-600">{message}</p>

        {/* Dev-only stacktrace */}
        {!isProd && !!stackTrace && (
          <pre className="mt-4 p-2.5 text-xs text-left whitespace-pre-wrap rounded-lg border border-gray-200">{stackTrace}</pre>
        )}

        {/* Support links */}
        <div className="mt-10 flex items-center justify-center gap-x-6">
          {!hideBackButton && (
            <StandardButton
              text="Go Home"
              onClick={() => navigate($path('/'))}
            />
          )}
          {!hideSupportLink && (
            <button className="text-sm font-semibold text-gray-900">
              Contact support <span aria-hidden="true">&rarr;</span>
            </button>
          )}
        </div>
      </div>
    </main>
  );
};


/**
 * A generic error boundary that can be used to catch errors in any location.
 */
export const GenericErrorBoundary = ({
  className,
  statusHandlers,
}: {
  /**
   * Additional class names to add to the error boundary container.
   */
  className?: string;

  /**
   * A map of status codes to status handlers. If a status code is not found in
   * this map, the default status handler will be used.
   */
  statusHandlers?: Record<number, (input: ErrorInfo<true>) => ReactNode | ErrorProps>;
}) => {
  const params = useParams();
  const _error = useRouteError();

  Sentry.captureRemixErrorBoundaryError(_error);

  // Parse the error message if it's a JSON string.
  const error = StandardError.from(_error);

  if (typeof document !== 'undefined') {
    // console.error('[ERROR BOUNDARY]:', error);
  }

  // Get the rendered error based on:
  // 1) Is the error a route error or other error?
  // 2) Is there a status handler for the error status code?
  // 3) If there is a status handler, does it return an object with props?
  // 4) If there is a status handler, does it return a React element?
  // 5) If none of the above, use the default status handler.
  //
  let RenderedError = DefaultStatusHandlerFunc({ error, params });
  if (isRouteErrorResponse(error) && statusHandlers?.[error.status]) {
    const statusHandlerRes = statusHandlers?.[error.status]?.({ error, params });
    if (statusHandlerRes && typeof statusHandlerRes === 'object' && 'message' in statusHandlerRes) {
      RenderedError = DefaultStatusHandlerFunc({ error, params, errorProps: statusHandlerRes });
    } else if (isValidElement(statusHandlerRes)) {
      RenderedError = statusHandlerRes;
    }
  }

  return (
    <div
      className={cn([
        'flex h-full w-full flex-col items-center justify-center px-2.5',
        className,
      ])}
    >
      {RenderedError}
    </div>
  );
};
